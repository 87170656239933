import $ from 'jquery';
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'magnific-popup';
import Headroom from 'headroom.js';
import sal from 'sal.js';
import 'slick-carousel';
import Cookies from 'js-cookie';

const Mangrove = {
  init() {
    this.cache();
    this.events();
    this.initSal();
    this.initHeadroom();
    this.initMobileDropdowns();
    this.initPopups();
    this.initVidOverlay();
    this.initSliders();
    this.openBanner();
    this.preCheckForm();
  },
  cache() {
    this.$doc = $(document);
    this.$win = $(window);
    this.$html = $('html');
    this.$body = $('body');
    this.$mainWrap = $('main');
    this.$header = $('.js-header');
    this.$mobileNav = $('.js-mobile-nav');
    this.$hamburger = $('.js-hamburger');
  },
  events() {
    this.$doc.on('click', '.js-hamburger', event => this.showMobileNav(event));
    this.$doc.on('click', '.js-share', event => this.openShareWindow(event));
    this.$doc.on('click', '.js-scrollto', event => this.scrollToSection(event));
    this.$doc.on('click', '.js-banner-close', event => this.closeBanner(event));
    this.$doc.on('click', '.js-email-fill', event => this.fillBannerEmail(event));
    this.$doc.on('click', '.js-footer-email-fill', event => this.fillFooterEmail(event));
    this.$doc.on('click', '.js-admin-email', event => this.fillAdminEmail(event));
    this.$doc.on('click', '.js-back-to-top', event => this.scrollToTop(event));
    this.$doc.on('click', '.js-open-search', event => this.openSearch(event));
  },
  initSal() {
    sal({
      rootMargin: '0% 20%',
      threshold: 0.25,
    });
  },
  openShareWindow(event) {
    let link = $(event.currentTarget).attr('href');
    if (link.substr(0, 6) != 'mailto') {
      event.preventDefault();
      window.open(link, '', 'width=600, height=400');
    }
  },
  scrollToSection(event) {
    const el = $(event.currentTarget);
    let section = el.attr('href');
    event.preventDefault();
    if (section) {
      $('html, body').animate({
        scrollTop: $(section).offset().top-140
      }, 500);
    }
  },
  scrollToTop(event) {
    event.preventDefault();
    $("html, body").animate({ scrollTop: 0 }, 500);
  },
  closeBanner(event) {
    event.preventDefault();
    $('.js-banner').addClass('closed');
    Cookies.set('banner', 'closed')
  },
  openSearch(event) {
    event.preventDefault();
    $('.js-search-banner').toggleClass('open');
  },
  fillBannerEmail() {
    let email = $('.js-banner-email').val();
    $('input.email').val(email);
  },
  fillFooterEmail() {
    let email = $('.js-footer-email').val();
    $('input.email').val(email);
  },
  fillAdminEmail(event) {
    const el = $(event.currentTarget);
    let email = el.attr('data-email');
    let bcc   = el.attr('data-bcc');
    let title = el.attr('data-title');
    $('#input_1_6').val(email);
    $('#input_1_7').val(bcc);
    $('.js-contact-title').html(title);
  },
  initHeadroom() {
    let theHeader = document.querySelector('.js-header');
    var options = {
      offset : 0,
      tolerance : 5,
      onPin : function() {
        $('.js-banner').removeClass("scroll-up");
      },
      onUnpin : function() {
        $('.js-banner').addClass("scroll-up");
      },
    };
    let headroom = new Headroom(theHeader, options);
    headroom.init();
  },
  initMobileDropdowns() {
    $('.js-mobile-nav .menu-item-has-children').attr('aria-expanded', 'false');
    $('.js-mobile-nav .menu-item-has-children').append( '<span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10"><path fill-rule="evenodd" d="M8 6.056L2.08.116 0 2.247c.016.022.029.045.047.064C2.487 4.774 7.985 10 7.985 10s4.558-4.284 6.53-6.275c.504-.508 1.003-1.02 1.485-1.51L13.845 0"/></svg></span>');
    $('.js-mobile-nav .menu-item-has-children > span').click(function() {
      $(this).siblings('.sub-menu').slideToggle(500);
      $(this).parent().toggleClass('submenu-active');
      $(this).parent().attr('aria-expanded',
        $(this).parent().attr('aria-expanded')=='false' ? 'true' : 'false'
      );
    });
  },
  openBanner() {
    if ( Cookies.get('banner') !== 'closed' ) {
      $('.js-banner').addClass("open");
    }
  },
  preCheckForm() {
    if ( $( "#js-groups" ).length ) {
      var keywords = $('#js-groups').val().split(", ");
      $.each(keywords, function(i, keyword){
        $(document.getElementById("email-auto-modal").getElementsByClassName(keyword)).prop("checked", true);
      });
    }
    $("#email-banner-modal .mce-group\\[4602\\]-4602-0").prop("checked", true);
  },
  initPopups() {
    $('.js-popup').magnificPopup({
      type:'inline',
      midClick: true,
      mainClass: 'popup--animate',
      callbacks: {
        close: function() {
          if ( $( ".js-internal-video" ).length ) {
            $('.js-internal-video').trigger('pause');
          }
          if ( $( ".js-contact-title" ).length ) {
            $('.js-contact-title').html('Get in Touch.');
          }
        }
      }
    });
    $('.js-desktop-popup').magnificPopup({
      type:'inline',
      midClick: true,
      mainClass: 'popup--animate',
      disableOn: function() {
        if( $(window).width() < 960 ) {
          return false;
        }
        return true;
      },
      callbacks: {
        close: function() {
          if ( $( ".js-internal-video" ).length ) {
            $('.js-internal-video').trigger('pause');
          }
          $('.js-desktop-popup').removeClass('active');
        }
      }
    });
    $( ".js-desktop-popup" ).click(function(event) {
      if ($(window).width() < 960) {
        event.preventDefault();
        $(this).toggleClass('active');
        $(this).siblings('.popup').slideToggle(500);
      }
    });
    $('.js-external-video-pop').magnificPopup({
      type: 'iframe',
      mainClass: 'popup--animate'
    });
    setTimeout(function() {
      if ($('.js-home-popup').length && Cookies.get('news-modal') !== 'close') {
        $.magnificPopup.open({
          items: {
              src: '#newsmodal'
          },
          type: 'inline',
          mainClass: 'popup--animate',
          callbacks: {
            open: function() {
              Cookies.set('news-modal', 'close', { expires: 1 })
            }
          }
        });
      }
    }, 1500);
    function autoPop() {
      if ($("body").hasClass("home")) {
        if ( Cookies.get('homemodal') !== 'isclosed' ) {
          if ($(document).scrollTop() > $(document).height() * 0.6) {
            $.magnificPopup.open({
              items: {
                  src: '#email-auto-modal'
              },
              type: 'inline',
              mainClass: 'popup--animate',
              callbacks: {
                open: function() {
                  Cookies.set('homemodal', 'isclosed', { expires: 14, path: '' })
                  Cookies.set('sitemodal', 'isclosed', { expires: 14, path: '' })
                }
              }
            });
          }
        }
      } else {
        if ( Cookies.get('sitemodal') !== 'isclosed' ) {
          if ($(document).scrollTop() > $(document).height() * 0.6) {
            $.magnificPopup.open({
              items: {
                  src: '#email-auto-modal'
              },
              type: 'inline',
              mainClass: 'popup--animate',
              callbacks: {
                open: function() {
                  Cookies.set('sitemodal', 'isclosed', { expires: 14, path: '' })
                  Cookies.set('homemodal', 'isclosed', { expires: 14, path: '' })
                }
              }
            });
          }
        }
      }
    }
    if ($('#email-auto-modal').length) {
      $(window).scroll(autoPop);
    }
  },
  initVidOverlay() {
    $('.js-internal-video').on('play', function() {
      $(this).parents('.text-media__popup').addClass('active');
    });
    $('.js-internal-video').on('pause', function() {
      $(this).parents('.text-media__popup').removeClass('active');
    });
  },
  showMobileNav(event) {
    event.preventDefault();
    this.$mobileNav.toggleClass('is-active');
    this.$hamburger.toggleClass('is-active');
    this.$header.toggleClass('is-active');
    if (this.$mobileNav.hasClass('is-active')) {
      this.$hamburger.attr('aria-pressed', 'true');
    } else {
      this.$hamburger.removeAttr('aria-pressed');
    }
  },
  initSliders() {
    $('.logo-slider').slick({
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 6,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
  },
};

Mangrove.init();
